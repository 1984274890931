import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import 'normalize.css';
import 'typeface-alegreya';
import 'typeface-source-sans-pro';

import './global.scss';

const Layout = ({ children, data }) => (
  <>
    <Helmet title="Anthony Bennett" />
    <div>{children}</div>
  </>
);

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;

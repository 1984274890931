import React, { Component } from 'react';

import Layout from '../components/layout';

import styles from './index.module.scss';

function classNames(...args) {
  return args.filter(e => !!e).join(' ');
}

const innerString = 'Anthony Bennett';

class IndexPage extends Component {
  state = {
    hover: null
  };

  handleHover = event => {
    this.setState({
      hover: event.currentTarget.name
    });
  };

  handleHoverOff = event => {
    this.setState({
      hover: null
    });
  };

  render() {
    const { hover } = this.state;

    return (
      <Layout>
        <div
          className={classNames(
            styles.component,
            styles.block,
            styles[`hover${hover}`],
            styles.isExpanded
          )}
        >
          {innerString}
          <ul>
            <li>
              <a
                href="https://twitter.com/anthonybnntt"
                target="_blank"
                rel="noopener noreferrer"
                name="twitter"
                onMouseEnter={this.handleHover}
                onMouseLeave={this.handleHoverOff}
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                href="http://instagram.com/anthonybnntt"
                target="_blank"
                rel="noopener noreferrer"
                name="instagram"
                onMouseEnter={this.handleHover}
                onMouseLeave={this.handleHoverOff}
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.strava.com/athletes/111673"
                target="_blank"
                rel="noopener noreferrer"
                name="strava"
                onMouseEnter={this.handleHover}
                onMouseLeave={this.handleHoverOff}
              >
                Strava
              </a>
            </li>
            <li>
              <a
                href="http://www.linkedin.com/in/anthony-bnntt"
                target="_blank"
                rel="noopener noreferrer"
                name="linkedin"
                onMouseEnter={this.handleHover}
                onMouseLeave={this.handleHoverOff}
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://github.com/tonybennett"
                target="_blank"
                rel="noopener noreferrer"
                name="github"
                onMouseEnter={this.handleHover}
                onMouseLeave={this.handleHoverOff}
              >
                Github
              </a>
            </li>
          </ul>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
